import React, {useEffect, useState} from 'react';
import VerseComponent from '../components/bible/VerseComponent';
import {Typography} from '@mui/material';
import RelatedComponent from "../components/related/RelatedComponent";
import NotesComponent from "../components/notes/NotesComponent";
import {useNavigate, useParams} from "react-router-dom";
import {APP_NAME} from "../constants";
import {LayoutStyle} from "../components/bible/VerseLayout";
import StyledContentHeader from "../styles/StyledContentHeader";
import StyledContentContainer from "../styles/StyledContentContainer";
import ScriptureAtom from "../model/Biblical/ScriptureAtom";
import MarkdownAtom from "../model/Core/MarkdownAtom";
import EmbeddedBlock from "../model/Core/EmbeddedBlock";
import {useSession} from "../contexts/SessionContext";
import {useMenu} from "../contexts/MenuContext";
import MenuItem from "@mui/material/MenuItem";
import {RelatedAtom} from "../model/Core/RelatedAtom";
import {useSnackbar} from "../contexts/SnackbarContext";
import {usePosts} from "../contexts/PostsContext";
import PostMolecule from "../model/Core/PostMolecule";
import {SemanticRef} from "../model/SemWeb/SemanticRef";
import Atom from "../model/Core/Atom";
import StrongsAtom from "../model/Biblical/StrongsAtom";
import StrongsNavigatorComponent from "../components/bible/StrongsNavigatorComponent";
import {Helmet} from "react-helmet-async";
import {PostMetadata} from "../model/Core/PostMetadata";
import {PostMetadataService} from "../services/PostMetadataService";

interface Props {
    onSelectPost: (ref: SemanticRef) => void;
}

const PostViewPage: React.FC<Props> = (props) => {
    const {onSelectPost} = props;
    const {id} = useParams();
    const [post, setPost] = useState<PostMolecule | undefined>(undefined);
    const [postMetadata, setPostMetadata] = useState<PostMetadata | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const navigate = useNavigate();
    const { setMenuItems } = useMenu();
    const postsContext = usePosts();
    const session = useSession();
    const snackbar = useSnackbar();

    if (error !== undefined) {
        throw error;
    }

    useEffect(() => {
        setMenuItems([
            <MenuItem key="edit-post" onClick={onEdit}>Edit</MenuItem>
        ]);

        // Clean up menu items when the component unmounts
        return () => setMenuItems([]);
    });

    useEffect(() => {
        async function fetchData() {
            if (id === undefined) {
                throw new Error("No post was provided. Please use the Search bar to select a post.");
            }
            const result = await postsContext.getPost(id);
            setPostMetadata(new PostMetadataService().getMetadata(result));
            document.title = `${APP_NAME} - ${result.title}`;

            return result;
        }

        fetchData()
            .then((result) => {
                setError(undefined);
                setPost(result);
            })
            .catch(error => setError(error));
    }, [id, postsContext]);

    const onEdit = () => {
        session.setPost(post!);
        navigate('/notes');
    }

    const onAddToSession = async (atom: Atom): Promise<void> => {
        await session.addAtom(atom);
        snackbar.showMessage(`${atom.getName()} added to notes.`);
    }

    return (
        <>
            <Helmet>
                <title>{post ? post.title : "Loading..."}</title>
                <meta property="og:title" content={postMetadata?.title ?? "Loading..."}/>
                <meta property="og:description" content={postMetadata?.description ?? ""}/>
                <meta property="og:image" content={postMetadata?.image ?? ""}/>
                <meta property="og:url" content={postMetadata?.url}/>
                <meta property="og:type" content={postMetadata?.type}/>
            </Helmet>
            <StyledContentHeader>
                <Typography variant="h5">
                {post?.title}
                </Typography>
            </StyledContentHeader>
            <StyledContentContainer>
                {post ? (
                    <>
                        {post.atoms.map((a, index) => {
                            if (a instanceof ScriptureAtom) {
                                return <VerseComponent
                                    key={index} scripture={a} onSelectPost={onSelectPost}
                                    onAddToSession={(s) => onAddToSession(s)}
                                    layout={LayoutStyle.Topic} />;
                            } else if (a instanceof MarkdownAtom) {
                                const note = (a.block as EmbeddedBlock).data;
                                return <NotesComponent key={index} notes={note} />;
                            } else if (a instanceof RelatedAtom) {
                                return <RelatedComponent atom={a} onSelectPost={props.onSelectPost} />;
                            } else if (a instanceof StrongsAtom) {
                                return <StrongsNavigatorComponent atom={a} />;
                            }
                            return null;
                        })}
                    </>
                ) : null}
            </StyledContentContainer>
        </>
    );
}

export default PostViewPage;