import PostMolecule from "../model/Core/PostMolecule";
import { PostMetadata } from "../model/Core/PostMetadata";
import ContentAtom from "../model/Core/ContentAtom";

export class PostMetadataService {
    public getMetadata(post?: PostMolecule): PostMetadata {
        if (!post) {
            return {
                title: "Not Found",
                description: undefined,
                image: undefined,
            } as PostMetadata;
        }

        return {
            title: post.title,
            description: this.getDescription(post),
            image: this.getImage(post),
            type: "article",
            url: `https://rtar.org/posts/${encodeURIComponent(post.id ?? "invalid")}`
        } as PostMetadata;
    }

    private getDescription(post: PostMolecule): string | undefined {
        const contentAtom = post.atoms.find(atom => atom instanceof ContentAtom) as ContentAtom | undefined;
        return contentAtom?.getContent().substring(0, 150);
    }

    private getImage(post: PostMolecule): string | undefined {
        for (const atom of post.atoms) {
            if (atom instanceof ContentAtom) {
                const content = atom.getContent();
                const imageMatch = content.match(/!\[([^\]]*)\]\(([^)\s]+)(?:\s+"[^"]*")?\)/);
                if (imageMatch) {
                    return imageMatch[2];
                }
            }
        }
        return undefined;
    }
}